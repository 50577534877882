// source: auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
var api_pb = require('./api_pb.js');
goog.object.extend(proto, api_pb);
goog.exportSymbol('proto.org.couchers.auth.AuthReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.AuthRes', null, global);
goog.exportSymbol('proto.org.couchers.auth.CompletePasswordResetV2Req', null, global);
goog.exportSymbol('proto.org.couchers.auth.ConfirmChangeEmailV2Req', null, global);
goog.exportSymbol('proto.org.couchers.auth.ConfirmDeleteAccountReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.ContributeOption', null, global);
goog.exportSymbol('proto.org.couchers.auth.ContributorForm', null, global);
goog.exportSymbol('proto.org.couchers.auth.GetAuthStateRes', null, global);
goog.exportSymbol('proto.org.couchers.auth.RecoverAccountReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.ResetPasswordReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.SignupAccount', null, global);
goog.exportSymbol('proto.org.couchers.auth.SignupBasic', null, global);
goog.exportSymbol('proto.org.couchers.auth.SignupFlowReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.SignupFlowRes', null, global);
goog.exportSymbol('proto.org.couchers.auth.SignupTokenInfoReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.UnsubscribeReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.UnsubscribeRes', null, global);
goog.exportSymbol('proto.org.couchers.auth.UsernameValidReq', null, global);
goog.exportSymbol('proto.org.couchers.auth.UsernameValidRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.SignupFlowReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.SignupFlowReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.SignupFlowReq.displayName = 'proto.org.couchers.auth.SignupFlowReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.SignupFlowRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.SignupFlowRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.SignupFlowRes.displayName = 'proto.org.couchers.auth.SignupFlowRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.SignupBasic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.SignupBasic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.SignupBasic.displayName = 'proto.org.couchers.auth.SignupBasic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.SignupTokenInfoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.SignupTokenInfoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.SignupTokenInfoReq.displayName = 'proto.org.couchers.auth.SignupTokenInfoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.SignupAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.SignupAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.SignupAccount.displayName = 'proto.org.couchers.auth.SignupAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.UsernameValidReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.UsernameValidReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.UsernameValidReq.displayName = 'proto.org.couchers.auth.UsernameValidReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.UsernameValidRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.UsernameValidRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.UsernameValidRes.displayName = 'proto.org.couchers.auth.UsernameValidRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.AuthReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.AuthReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.AuthReq.displayName = 'proto.org.couchers.auth.AuthReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.AuthRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.AuthRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.AuthRes.displayName = 'proto.org.couchers.auth.AuthRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.GetAuthStateRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.GetAuthStateRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.GetAuthStateRes.displayName = 'proto.org.couchers.auth.GetAuthStateRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.ResetPasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.ResetPasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.ResetPasswordReq.displayName = 'proto.org.couchers.auth.ResetPasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.CompletePasswordResetV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.CompletePasswordResetV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.CompletePasswordResetV2Req.displayName = 'proto.org.couchers.auth.CompletePasswordResetV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.ConfirmChangeEmailV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.ConfirmChangeEmailV2Req.displayName = 'proto.org.couchers.auth.ConfirmChangeEmailV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.ContributorForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.auth.ContributorForm.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.auth.ContributorForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.ContributorForm.displayName = 'proto.org.couchers.auth.ContributorForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.ConfirmDeleteAccountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.ConfirmDeleteAccountReq.displayName = 'proto.org.couchers.auth.ConfirmDeleteAccountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.RecoverAccountReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.RecoverAccountReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.RecoverAccountReq.displayName = 'proto.org.couchers.auth.RecoverAccountReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.UnsubscribeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.UnsubscribeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.UnsubscribeReq.displayName = 'proto.org.couchers.auth.UnsubscribeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.auth.UnsubscribeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.auth.UnsubscribeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.auth.UnsubscribeRes.displayName = 'proto.org.couchers.auth.UnsubscribeRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.SignupFlowReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.SignupFlowReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupFlowReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    basic: (f = msg.getBasic()) && proto.org.couchers.auth.SignupBasic.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && proto.org.couchers.auth.SignupAccount.toObject(includeInstance, f),
    feedback: (f = msg.getFeedback()) && proto.org.couchers.auth.ContributorForm.toObject(includeInstance, f),
    emailToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    acceptCommunityGuidelines: (f = msg.getAcceptCommunityGuidelines()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.SignupFlowReq}
 */
proto.org.couchers.auth.SignupFlowReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.SignupFlowReq;
  return proto.org.couchers.auth.SignupFlowReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.SignupFlowReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.SignupFlowReq}
 */
proto.org.couchers.auth.SignupFlowReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowToken(value);
      break;
    case 2:
      var value = new proto.org.couchers.auth.SignupBasic;
      reader.readMessage(value,proto.org.couchers.auth.SignupBasic.deserializeBinaryFromReader);
      msg.setBasic(value);
      break;
    case 3:
      var value = new proto.org.couchers.auth.SignupAccount;
      reader.readMessage(value,proto.org.couchers.auth.SignupAccount.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 4:
      var value = new proto.org.couchers.auth.ContributorForm;
      reader.readMessage(value,proto.org.couchers.auth.ContributorForm.deserializeBinaryFromReader);
      msg.setFeedback(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailToken(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAcceptCommunityGuidelines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.SignupFlowReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.SignupFlowReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupFlowReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBasic();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.org.couchers.auth.SignupBasic.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.org.couchers.auth.SignupAccount.serializeBinaryToWriter
    );
  }
  f = message.getFeedback();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.org.couchers.auth.ContributorForm.serializeBinaryToWriter
    );
  }
  f = message.getEmailToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAcceptCommunityGuidelines();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flow_token = 1;
 * @return {string}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.getFlowToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
 */
proto.org.couchers.auth.SignupFlowReq.prototype.setFlowToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SignupBasic basic = 2;
 * @return {?proto.org.couchers.auth.SignupBasic}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.getBasic = function() {
  return /** @type{?proto.org.couchers.auth.SignupBasic} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.auth.SignupBasic, 2));
};


/**
 * @param {?proto.org.couchers.auth.SignupBasic|undefined} value
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
*/
proto.org.couchers.auth.SignupFlowReq.prototype.setBasic = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
 */
proto.org.couchers.auth.SignupFlowReq.prototype.clearBasic = function() {
  return this.setBasic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.hasBasic = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SignupAccount account = 3;
 * @return {?proto.org.couchers.auth.SignupAccount}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.getAccount = function() {
  return /** @type{?proto.org.couchers.auth.SignupAccount} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.auth.SignupAccount, 3));
};


/**
 * @param {?proto.org.couchers.auth.SignupAccount|undefined} value
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
*/
proto.org.couchers.auth.SignupFlowReq.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
 */
proto.org.couchers.auth.SignupFlowReq.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ContributorForm feedback = 4;
 * @return {?proto.org.couchers.auth.ContributorForm}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.getFeedback = function() {
  return /** @type{?proto.org.couchers.auth.ContributorForm} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.auth.ContributorForm, 4));
};


/**
 * @param {?proto.org.couchers.auth.ContributorForm|undefined} value
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
*/
proto.org.couchers.auth.SignupFlowReq.prototype.setFeedback = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
 */
proto.org.couchers.auth.SignupFlowReq.prototype.clearFeedback = function() {
  return this.setFeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.hasFeedback = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string email_token = 5;
 * @return {string}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.getEmailToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
 */
proto.org.couchers.auth.SignupFlowReq.prototype.setEmailToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.BoolValue accept_community_guidelines = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.getAcceptCommunityGuidelines = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
*/
proto.org.couchers.auth.SignupFlowReq.prototype.setAcceptCommunityGuidelines = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.auth.SignupFlowReq} returns this
 */
proto.org.couchers.auth.SignupFlowReq.prototype.clearAcceptCommunityGuidelines = function() {
  return this.setAcceptCommunityGuidelines(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowReq.prototype.hasAcceptCommunityGuidelines = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.SignupFlowRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.SignupFlowRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupFlowRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    flowToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authRes: (f = msg.getAuthRes()) && proto.org.couchers.auth.AuthRes.toObject(includeInstance, f),
    needBasic: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    needAccount: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    needFeedback: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    needVerifyEmail: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    needAcceptCommunityGuidelines: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.SignupFlowRes}
 */
proto.org.couchers.auth.SignupFlowRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.SignupFlowRes;
  return proto.org.couchers.auth.SignupFlowRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.SignupFlowRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.SignupFlowRes}
 */
proto.org.couchers.auth.SignupFlowRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlowToken(value);
      break;
    case 3:
      var value = new proto.org.couchers.auth.AuthRes;
      reader.readMessage(value,proto.org.couchers.auth.AuthRes.deserializeBinaryFromReader);
      msg.setAuthRes(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedBasic(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedAccount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedFeedback(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedVerifyEmail(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedAcceptCommunityGuidelines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.SignupFlowRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.SignupFlowRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupFlowRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlowToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthRes();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.org.couchers.auth.AuthRes.serializeBinaryToWriter
    );
  }
  f = message.getNeedBasic();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getNeedAccount();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getNeedFeedback();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNeedVerifyEmail();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getNeedAcceptCommunityGuidelines();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string flow_token = 1;
 * @return {string}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.getFlowToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
 */
proto.org.couchers.auth.SignupFlowRes.prototype.setFlowToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AuthRes auth_res = 3;
 * @return {?proto.org.couchers.auth.AuthRes}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.getAuthRes = function() {
  return /** @type{?proto.org.couchers.auth.AuthRes} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.auth.AuthRes, 3));
};


/**
 * @param {?proto.org.couchers.auth.AuthRes|undefined} value
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
*/
proto.org.couchers.auth.SignupFlowRes.prototype.setAuthRes = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
 */
proto.org.couchers.auth.SignupFlowRes.prototype.clearAuthRes = function() {
  return this.setAuthRes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.hasAuthRes = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool need_basic = 4;
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.getNeedBasic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
 */
proto.org.couchers.auth.SignupFlowRes.prototype.setNeedBasic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool need_account = 5;
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.getNeedAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
 */
proto.org.couchers.auth.SignupFlowRes.prototype.setNeedAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool need_feedback = 6;
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.getNeedFeedback = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
 */
proto.org.couchers.auth.SignupFlowRes.prototype.setNeedFeedback = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool need_verify_email = 7;
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.getNeedVerifyEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
 */
proto.org.couchers.auth.SignupFlowRes.prototype.setNeedVerifyEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool need_accept_community_guidelines = 8;
 * @return {boolean}
 */
proto.org.couchers.auth.SignupFlowRes.prototype.getNeedAcceptCommunityGuidelines = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.SignupFlowRes} returns this
 */
proto.org.couchers.auth.SignupFlowRes.prototype.setNeedAcceptCommunityGuidelines = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.SignupBasic.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.SignupBasic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.SignupBasic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupBasic.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.SignupBasic}
 */
proto.org.couchers.auth.SignupBasic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.SignupBasic;
  return proto.org.couchers.auth.SignupBasic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.SignupBasic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.SignupBasic}
 */
proto.org.couchers.auth.SignupBasic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.SignupBasic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.SignupBasic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.SignupBasic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupBasic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.org.couchers.auth.SignupBasic.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupBasic} returns this
 */
proto.org.couchers.auth.SignupBasic.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.org.couchers.auth.SignupBasic.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupBasic} returns this
 */
proto.org.couchers.auth.SignupBasic.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.SignupTokenInfoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.SignupTokenInfoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.SignupTokenInfoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupTokenInfoReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    signupToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.SignupTokenInfoReq}
 */
proto.org.couchers.auth.SignupTokenInfoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.SignupTokenInfoReq;
  return proto.org.couchers.auth.SignupTokenInfoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.SignupTokenInfoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.SignupTokenInfoReq}
 */
proto.org.couchers.auth.SignupTokenInfoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignupToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.SignupTokenInfoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.SignupTokenInfoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.SignupTokenInfoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupTokenInfoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignupToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string signup_token = 1;
 * @return {string}
 */
proto.org.couchers.auth.SignupTokenInfoReq.prototype.getSignupToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupTokenInfoReq} returns this
 */
proto.org.couchers.auth.SignupTokenInfoReq.prototype.setSignupToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.SignupAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.SignupAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.SignupAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    city: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    birthdate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hostingStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    acceptTos: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    optOutOfNewsletter: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.SignupAccount}
 */
proto.org.couchers.auth.SignupAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.SignupAccount;
  return proto.org.couchers.auth.SignupAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.SignupAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.SignupAccount}
 */
proto.org.couchers.auth.SignupAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthdate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 5:
      var value = /** @type {!proto.org.couchers.api.core.HostingStatus} */ (reader.readEnum());
      msg.setHostingStatus(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAcceptTos(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOptOutOfNewsletter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.SignupAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.SignupAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.SignupAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.SignupAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getBirthdate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHostingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAcceptTos();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getOptOutOfNewsletter();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.org.couchers.auth.SignupAccount.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.org.couchers.auth.SignupAccount.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string city = 6;
 * @return {string}
 */
proto.org.couchers.auth.SignupAccount.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double lat = 7;
 * @return {number}
 */
proto.org.couchers.auth.SignupAccount.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double lng = 8;
 * @return {number}
 */
proto.org.couchers.auth.SignupAccount.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double radius = 9;
 * @return {number}
 */
proto.org.couchers.auth.SignupAccount.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string birthdate = 3;
 * @return {string}
 */
proto.org.couchers.auth.SignupAccount.prototype.getBirthdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string gender = 4;
 * @return {string}
 */
proto.org.couchers.auth.SignupAccount.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional org.couchers.api.core.HostingStatus hosting_status = 5;
 * @return {!proto.org.couchers.api.core.HostingStatus}
 */
proto.org.couchers.auth.SignupAccount.prototype.getHostingStatus = function() {
  return /** @type {!proto.org.couchers.api.core.HostingStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.org.couchers.api.core.HostingStatus} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setHostingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool accept_tos = 10;
 * @return {boolean}
 */
proto.org.couchers.auth.SignupAccount.prototype.getAcceptTos = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setAcceptTos = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool opt_out_of_newsletter = 11;
 * @return {boolean}
 */
proto.org.couchers.auth.SignupAccount.prototype.getOptOutOfNewsletter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.SignupAccount} returns this
 */
proto.org.couchers.auth.SignupAccount.prototype.setOptOutOfNewsletter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.UsernameValidReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.UsernameValidReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.UsernameValidReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UsernameValidReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.UsernameValidReq}
 */
proto.org.couchers.auth.UsernameValidReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.UsernameValidReq;
  return proto.org.couchers.auth.UsernameValidReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.UsernameValidReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.UsernameValidReq}
 */
proto.org.couchers.auth.UsernameValidReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.UsernameValidReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.UsernameValidReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.UsernameValidReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UsernameValidReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.org.couchers.auth.UsernameValidReq.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.UsernameValidReq} returns this
 */
proto.org.couchers.auth.UsernameValidReq.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.UsernameValidRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.UsernameValidRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.UsernameValidRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UsernameValidRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.UsernameValidRes}
 */
proto.org.couchers.auth.UsernameValidRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.UsernameValidRes;
  return proto.org.couchers.auth.UsernameValidRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.UsernameValidRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.UsernameValidRes}
 */
proto.org.couchers.auth.UsernameValidRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.UsernameValidRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.UsernameValidRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.UsernameValidRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UsernameValidRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.org.couchers.auth.UsernameValidRes.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.UsernameValidRes} returns this
 */
proto.org.couchers.auth.UsernameValidRes.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.AuthReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.AuthReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.AuthReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.AuthReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rememberDevice: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.AuthReq}
 */
proto.org.couchers.auth.AuthReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.AuthReq;
  return proto.org.couchers.auth.AuthReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.AuthReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.AuthReq}
 */
proto.org.couchers.auth.AuthReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRememberDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.AuthReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.AuthReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.AuthReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.AuthReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRememberDevice();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.org.couchers.auth.AuthReq.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.AuthReq} returns this
 */
proto.org.couchers.auth.AuthReq.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.org.couchers.auth.AuthReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.AuthReq} returns this
 */
proto.org.couchers.auth.AuthReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool remember_device = 3;
 * @return {boolean}
 */
proto.org.couchers.auth.AuthReq.prototype.getRememberDevice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.AuthReq} returns this
 */
proto.org.couchers.auth.AuthReq.prototype.setRememberDevice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.AuthRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.AuthRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.AuthRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.AuthRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    jailed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.AuthRes}
 */
proto.org.couchers.auth.AuthRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.AuthRes;
  return proto.org.couchers.auth.AuthRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.AuthRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.AuthRes}
 */
proto.org.couchers.auth.AuthRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setJailed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.AuthRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.AuthRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.AuthRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.AuthRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getJailed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.org.couchers.auth.AuthRes.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.auth.AuthRes} returns this
 */
proto.org.couchers.auth.AuthRes.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool jailed = 2;
 * @return {boolean}
 */
proto.org.couchers.auth.AuthRes.prototype.getJailed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.AuthRes} returns this
 */
proto.org.couchers.auth.AuthRes.prototype.setJailed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.GetAuthStateRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.GetAuthStateRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.GetAuthStateRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.GetAuthStateRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    loggedIn: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    authRes: (f = msg.getAuthRes()) && proto.org.couchers.auth.AuthRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.GetAuthStateRes}
 */
proto.org.couchers.auth.GetAuthStateRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.GetAuthStateRes;
  return proto.org.couchers.auth.GetAuthStateRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.GetAuthStateRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.GetAuthStateRes}
 */
proto.org.couchers.auth.GetAuthStateRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoggedIn(value);
      break;
    case 2:
      var value = new proto.org.couchers.auth.AuthRes;
      reader.readMessage(value,proto.org.couchers.auth.AuthRes.deserializeBinaryFromReader);
      msg.setAuthRes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.GetAuthStateRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.GetAuthStateRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.GetAuthStateRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.GetAuthStateRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoggedIn();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getAuthRes();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.org.couchers.auth.AuthRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool logged_in = 1;
 * @return {boolean}
 */
proto.org.couchers.auth.GetAuthStateRes.prototype.getLoggedIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.auth.GetAuthStateRes} returns this
 */
proto.org.couchers.auth.GetAuthStateRes.prototype.setLoggedIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional AuthRes auth_res = 2;
 * @return {?proto.org.couchers.auth.AuthRes}
 */
proto.org.couchers.auth.GetAuthStateRes.prototype.getAuthRes = function() {
  return /** @type{?proto.org.couchers.auth.AuthRes} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.auth.AuthRes, 2));
};


/**
 * @param {?proto.org.couchers.auth.AuthRes|undefined} value
 * @return {!proto.org.couchers.auth.GetAuthStateRes} returns this
*/
proto.org.couchers.auth.GetAuthStateRes.prototype.setAuthRes = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.auth.GetAuthStateRes} returns this
 */
proto.org.couchers.auth.GetAuthStateRes.prototype.clearAuthRes = function() {
  return this.setAuthRes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.auth.GetAuthStateRes.prototype.hasAuthRes = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.ResetPasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.ResetPasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.ResetPasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ResetPasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.ResetPasswordReq}
 */
proto.org.couchers.auth.ResetPasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.ResetPasswordReq;
  return proto.org.couchers.auth.ResetPasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.ResetPasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.ResetPasswordReq}
 */
proto.org.couchers.auth.ResetPasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.ResetPasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.ResetPasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.ResetPasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ResetPasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.org.couchers.auth.ResetPasswordReq.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.ResetPasswordReq} returns this
 */
proto.org.couchers.auth.ResetPasswordReq.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.CompletePasswordResetV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.CompletePasswordResetV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    passwordResetToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.CompletePasswordResetV2Req}
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.CompletePasswordResetV2Req;
  return proto.org.couchers.auth.CompletePasswordResetV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.CompletePasswordResetV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.CompletePasswordResetV2Req}
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordResetToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.CompletePasswordResetV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.CompletePasswordResetV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPasswordResetToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string password_reset_token = 1;
 * @return {string}
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.prototype.getPasswordResetToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.CompletePasswordResetV2Req} returns this
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.prototype.setPasswordResetToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.CompletePasswordResetV2Req} returns this
 */
proto.org.couchers.auth.CompletePasswordResetV2Req.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.ConfirmChangeEmailV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.ConfirmChangeEmailV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeEmailToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.ConfirmChangeEmailV2Req}
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.ConfirmChangeEmailV2Req;
  return proto.org.couchers.auth.ConfirmChangeEmailV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.ConfirmChangeEmailV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.ConfirmChangeEmailV2Req}
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangeEmailToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.ConfirmChangeEmailV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.ConfirmChangeEmailV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeEmailToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string change_email_token = 1;
 * @return {string}
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.prototype.getChangeEmailToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.ConfirmChangeEmailV2Req} returns this
 */
proto.org.couchers.auth.ConfirmChangeEmailV2Req.prototype.setChangeEmailToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.auth.ContributorForm.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.ContributorForm.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.ContributorForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.ContributorForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ContributorForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    ideas: jspb.Message.getFieldWithDefault(msg, 1, ""),
    features: jspb.Message.getFieldWithDefault(msg, 2, ""),
    experience: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contribute: jspb.Message.getFieldWithDefault(msg, 4, 0),
    contributeWaysList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    expertise: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.ContributorForm}
 */
proto.org.couchers.auth.ContributorForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.ContributorForm;
  return proto.org.couchers.auth.ContributorForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.ContributorForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.ContributorForm}
 */
proto.org.couchers.auth.ContributorForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdeas(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeatures(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExperience(value);
      break;
    case 4:
      var value = /** @type {!proto.org.couchers.auth.ContributeOption} */ (reader.readEnum());
      msg.setContribute(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addContributeWays(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpertise(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.ContributorForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.ContributorForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.ContributorForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ContributorForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdeas();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeatures();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExperience();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContribute();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getContributeWaysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getExpertise();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string ideas = 1;
 * @return {string}
 */
proto.org.couchers.auth.ContributorForm.prototype.getIdeas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.setIdeas = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string features = 2;
 * @return {string}
 */
proto.org.couchers.auth.ContributorForm.prototype.getFeatures = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.setFeatures = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string experience = 3;
 * @return {string}
 */
proto.org.couchers.auth.ContributorForm.prototype.getExperience = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.setExperience = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ContributeOption contribute = 4;
 * @return {!proto.org.couchers.auth.ContributeOption}
 */
proto.org.couchers.auth.ContributorForm.prototype.getContribute = function() {
  return /** @type {!proto.org.couchers.auth.ContributeOption} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.org.couchers.auth.ContributeOption} value
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.setContribute = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated string contribute_ways = 5;
 * @return {!Array<string>}
 */
proto.org.couchers.auth.ContributorForm.prototype.getContributeWaysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.setContributeWaysList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.addContributeWays = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.clearContributeWaysList = function() {
  return this.setContributeWaysList([]);
};


/**
 * optional string expertise = 6;
 * @return {string}
 */
proto.org.couchers.auth.ContributorForm.prototype.getExpertise = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.ContributorForm} returns this
 */
proto.org.couchers.auth.ContributorForm.prototype.setExpertise = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.ConfirmDeleteAccountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.ConfirmDeleteAccountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.ConfirmDeleteAccountReq}
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.ConfirmDeleteAccountReq;
  return proto.org.couchers.auth.ConfirmDeleteAccountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.ConfirmDeleteAccountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.ConfirmDeleteAccountReq}
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.ConfirmDeleteAccountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.ConfirmDeleteAccountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.ConfirmDeleteAccountReq} returns this
 */
proto.org.couchers.auth.ConfirmDeleteAccountReq.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.RecoverAccountReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.RecoverAccountReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.RecoverAccountReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.RecoverAccountReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.RecoverAccountReq}
 */
proto.org.couchers.auth.RecoverAccountReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.RecoverAccountReq;
  return proto.org.couchers.auth.RecoverAccountReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.RecoverAccountReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.RecoverAccountReq}
 */
proto.org.couchers.auth.RecoverAccountReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.RecoverAccountReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.RecoverAccountReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.RecoverAccountReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.RecoverAccountReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.org.couchers.auth.RecoverAccountReq.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.RecoverAccountReq} returns this
 */
proto.org.couchers.auth.RecoverAccountReq.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.UnsubscribeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.UnsubscribeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UnsubscribeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    payload: msg.getPayload_asB64(),
    sig: msg.getSig_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.UnsubscribeReq}
 */
proto.org.couchers.auth.UnsubscribeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.UnsubscribeReq;
  return proto.org.couchers.auth.UnsubscribeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.UnsubscribeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.UnsubscribeReq}
 */
proto.org.couchers.auth.UnsubscribeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.UnsubscribeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.UnsubscribeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UnsubscribeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getSig_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes payload = 1;
 * @return {!(string|Uint8Array)}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes payload = 1;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.org.couchers.auth.UnsubscribeReq} returns this
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.setPayload = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes sig = 2;
 * @return {!(string|Uint8Array)}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.getSig = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes sig = 2;
 * This is a type-conversion wrapper around `getSig()`
 * @return {string}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.getSig_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSig()));
};


/**
 * optional bytes sig = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSig()`
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.getSig_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSig()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.org.couchers.auth.UnsubscribeReq} returns this
 */
proto.org.couchers.auth.UnsubscribeReq.prototype.setSig = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.auth.UnsubscribeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.auth.UnsubscribeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.auth.UnsubscribeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UnsubscribeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    response: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.auth.UnsubscribeRes}
 */
proto.org.couchers.auth.UnsubscribeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.auth.UnsubscribeRes;
  return proto.org.couchers.auth.UnsubscribeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.auth.UnsubscribeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.auth.UnsubscribeRes}
 */
proto.org.couchers.auth.UnsubscribeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.auth.UnsubscribeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.auth.UnsubscribeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.auth.UnsubscribeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.auth.UnsubscribeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponse();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string response = 1;
 * @return {string}
 */
proto.org.couchers.auth.UnsubscribeRes.prototype.getResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.auth.UnsubscribeRes} returns this
 */
proto.org.couchers.auth.UnsubscribeRes.prototype.setResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.org.couchers.auth.ContributeOption = {
  CONTRIBUTE_OPTION_UNSPECIFIED: 0,
  CONTRIBUTE_OPTION_YES: 1,
  CONTRIBUTE_OPTION_MAYBE: 2,
  CONTRIBUTE_OPTION_NO: 3
};

goog.object.extend(exports, proto.org.couchers.auth);
