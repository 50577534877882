// source: search.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
var api_pb = require('./api_pb.js');
goog.object.extend(proto, api_pb);
var communities_pb = require('./communities_pb.js');
goog.object.extend(proto, communities_pb);
var events_pb = require('./events_pb.js');
goog.object.extend(proto, events_pb);
var groups_pb = require('./groups_pb.js');
goog.object.extend(proto, groups_pb);
var pages_pb = require('./pages_pb.js');
goog.object.extend(proto, pages_pb);
goog.exportSymbol('proto.org.couchers.api.search.Area', null, global);
goog.exportSymbol('proto.org.couchers.api.search.EventSearchReq', null, global);
goog.exportSymbol('proto.org.couchers.api.search.EventSearchReq.OnlineStatusCase', null, global);
goog.exportSymbol('proto.org.couchers.api.search.EventSearchReq.SearchInCase', null, global);
goog.exportSymbol('proto.org.couchers.api.search.EventSearchRes', null, global);
goog.exportSymbol('proto.org.couchers.api.search.RectArea', null, global);
goog.exportSymbol('proto.org.couchers.api.search.Result', null, global);
goog.exportSymbol('proto.org.couchers.api.search.Result.ResultCase', null, global);
goog.exportSymbol('proto.org.couchers.api.search.SearchReq', null, global);
goog.exportSymbol('proto.org.couchers.api.search.SearchRes', null, global);
goog.exportSymbol('proto.org.couchers.api.search.UserSearchReq', null, global);
goog.exportSymbol('proto.org.couchers.api.search.UserSearchReq.SearchInCase', null, global);
goog.exportSymbol('proto.org.couchers.api.search.UserSearchRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.Area = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.search.Area, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.Area.displayName = 'proto.org.couchers.api.search.Area';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.RectArea = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.search.RectArea, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.RectArea.displayName = 'proto.org.couchers.api.search.RectArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.SearchReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.search.SearchReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.SearchReq.displayName = 'proto.org.couchers.api.search.SearchReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.search.Result.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.search.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.Result.displayName = 'proto.org.couchers.api.search.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.SearchRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.search.SearchRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.search.SearchRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.SearchRes.displayName = 'proto.org.couchers.api.search.SearchRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.UserSearchReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.search.UserSearchReq.repeatedFields_, proto.org.couchers.api.search.UserSearchReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.search.UserSearchReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.UserSearchReq.displayName = 'proto.org.couchers.api.search.UserSearchReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.UserSearchRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.search.UserSearchRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.search.UserSearchRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.UserSearchRes.displayName = 'proto.org.couchers.api.search.UserSearchRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.EventSearchReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.search.EventSearchReq.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.search.EventSearchReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.EventSearchReq.displayName = 'proto.org.couchers.api.search.EventSearchReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.search.EventSearchRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.search.EventSearchRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.search.EventSearchRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.search.EventSearchRes.displayName = 'proto.org.couchers.api.search.EventSearchRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.Area.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.Area.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.Area} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.Area.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.Area}
 */
proto.org.couchers.api.search.Area.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.Area;
  return proto.org.couchers.api.search.Area.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.Area} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.Area}
 */
proto.org.couchers.api.search.Area.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.Area.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.Area.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.Area} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.Area.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.org.couchers.api.search.Area.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.Area} returns this
 */
proto.org.couchers.api.search.Area.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lng = 2;
 * @return {number}
 */
proto.org.couchers.api.search.Area.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.Area} returns this
 */
proto.org.couchers.api.search.Area.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double radius = 3;
 * @return {number}
 */
proto.org.couchers.api.search.Area.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.Area} returns this
 */
proto.org.couchers.api.search.Area.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.RectArea.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.RectArea.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.RectArea} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.RectArea.toObject = function(includeInstance, msg) {
  var f, obj = {
    latMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    latMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lngMin: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lngMax: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.RectArea}
 */
proto.org.couchers.api.search.RectArea.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.RectArea;
  return proto.org.couchers.api.search.RectArea.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.RectArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.RectArea}
 */
proto.org.couchers.api.search.RectArea.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatMax(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLngMin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLngMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.RectArea.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.RectArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.RectArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.RectArea.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatMin();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLatMax();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLngMin();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLngMax();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double lat_min = 1;
 * @return {number}
 */
proto.org.couchers.api.search.RectArea.prototype.getLatMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.RectArea} returns this
 */
proto.org.couchers.api.search.RectArea.prototype.setLatMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lat_max = 2;
 * @return {number}
 */
proto.org.couchers.api.search.RectArea.prototype.getLatMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.RectArea} returns this
 */
proto.org.couchers.api.search.RectArea.prototype.setLatMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double lng_min = 3;
 * @return {number}
 */
proto.org.couchers.api.search.RectArea.prototype.getLngMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.RectArea} returns this
 */
proto.org.couchers.api.search.RectArea.prototype.setLngMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double lng_max = 4;
 * @return {number}
 */
proto.org.couchers.api.search.RectArea.prototype.getLngMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.RectArea} returns this
 */
proto.org.couchers.api.search.RectArea.prototype.setLngMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.SearchReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.SearchReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.SearchReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.SearchReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeUsers: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    includeCommunities: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    includeGroups: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    includePlaces: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    includeGuides: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    includeEvents: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    titleOnly: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.SearchReq}
 */
proto.org.couchers.api.search.SearchReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.SearchReq;
  return proto.org.couchers.api.search.SearchReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.SearchReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.SearchReq}
 */
proto.org.couchers.api.search.SearchReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeUsers(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCommunities(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeGroups(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePlaces(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeGuides(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeEvents(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTitleOnly(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.SearchReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.SearchReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.SearchReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.SearchReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeUsers();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIncludeCommunities();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIncludeGroups();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIncludePlaces();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIncludeGuides();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIncludeEvents();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getTitleOnly();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.org.couchers.api.search.SearchReq.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_users = 4;
 * @return {boolean}
 */
proto.org.couchers.api.search.SearchReq.prototype.getIncludeUsers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setIncludeUsers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool include_communities = 5;
 * @return {boolean}
 */
proto.org.couchers.api.search.SearchReq.prototype.getIncludeCommunities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setIncludeCommunities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool include_groups = 6;
 * @return {boolean}
 */
proto.org.couchers.api.search.SearchReq.prototype.getIncludeGroups = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setIncludeGroups = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool include_places = 7;
 * @return {boolean}
 */
proto.org.couchers.api.search.SearchReq.prototype.getIncludePlaces = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setIncludePlaces = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool include_guides = 8;
 * @return {boolean}
 */
proto.org.couchers.api.search.SearchReq.prototype.getIncludeGuides = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setIncludeGuides = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool include_events = 10;
 * @return {boolean}
 */
proto.org.couchers.api.search.SearchReq.prototype.getIncludeEvents = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setIncludeEvents = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool title_only = 13;
 * @return {boolean}
 */
proto.org.couchers.api.search.SearchReq.prototype.getTitleOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setTitleOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.search.SearchReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.search.SearchReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.SearchReq} returns this
 */
proto.org.couchers.api.search.SearchReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.search.Result.oneofGroups_ = [[3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.org.couchers.api.search.Result.ResultCase = {
  RESULT_NOT_SET: 0,
  USER: 3,
  COMMUNITY: 4,
  GROUP: 5,
  PLACE: 6,
  GUIDE: 7,
  EVENT: 8
};

/**
 * @return {proto.org.couchers.api.search.Result.ResultCase}
 */
proto.org.couchers.api.search.Result.prototype.getResultCase = function() {
  return /** @type {proto.org.couchers.api.search.Result.ResultCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.search.Result.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    rank: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    snippet: jspb.Message.getFieldWithDefault(msg, 2, ""),
    user: (f = msg.getUser()) && api_pb.User.toObject(includeInstance, f),
    community: (f = msg.getCommunity()) && communities_pb.Community.toObject(includeInstance, f),
    group: (f = msg.getGroup()) && groups_pb.Group.toObject(includeInstance, f),
    place: (f = msg.getPlace()) && pages_pb.Page.toObject(includeInstance, f),
    guide: (f = msg.getGuide()) && pages_pb.Page.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && events_pb.Event.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.Result}
 */
proto.org.couchers.api.search.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.Result;
  return proto.org.couchers.api.search.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.Result}
 */
proto.org.couchers.api.search.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRank(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnippet(value);
      break;
    case 3:
      var value = new api_pb.User;
      reader.readMessage(value,api_pb.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = new communities_pb.Community;
      reader.readMessage(value,communities_pb.Community.deserializeBinaryFromReader);
      msg.setCommunity(value);
      break;
    case 5:
      var value = new groups_pb.Group;
      reader.readMessage(value,groups_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    case 6:
      var value = new pages_pb.Page;
      reader.readMessage(value,pages_pb.Page.deserializeBinaryFromReader);
      msg.setPlace(value);
      break;
    case 7:
      var value = new pages_pb.Page;
      reader.readMessage(value,pages_pb.Page.deserializeBinaryFromReader);
      msg.setGuide(value);
      break;
    case 8:
      var value = new events_pb.Event;
      reader.readMessage(value,events_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRank();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSnippet();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      api_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getCommunity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      communities_pb.Community.serializeBinaryToWriter
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      groups_pb.Group.serializeBinaryToWriter
    );
  }
  f = message.getPlace();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pages_pb.Page.serializeBinaryToWriter
    );
  }
  f = message.getGuide();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pages_pb.Page.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      events_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional double rank = 1;
 * @return {number}
 */
proto.org.couchers.api.search.Result.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.setRank = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string snippet = 2;
 * @return {string}
 */
proto.org.couchers.api.search.Result.prototype.getSnippet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.setSnippet = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional org.couchers.api.core.User user = 3;
 * @return {?proto.org.couchers.api.core.User}
 */
proto.org.couchers.api.search.Result.prototype.getUser = function() {
  return /** @type{?proto.org.couchers.api.core.User} */ (
    jspb.Message.getWrapperField(this, api_pb.User, 3));
};


/**
 * @param {?proto.org.couchers.api.core.User|undefined} value
 * @return {!proto.org.couchers.api.search.Result} returns this
*/
proto.org.couchers.api.search.Result.prototype.setUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.org.couchers.api.search.Result.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.Result.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional org.couchers.api.communities.Community community = 4;
 * @return {?proto.org.couchers.api.communities.Community}
 */
proto.org.couchers.api.search.Result.prototype.getCommunity = function() {
  return /** @type{?proto.org.couchers.api.communities.Community} */ (
    jspb.Message.getWrapperField(this, communities_pb.Community, 4));
};


/**
 * @param {?proto.org.couchers.api.communities.Community|undefined} value
 * @return {!proto.org.couchers.api.search.Result} returns this
*/
proto.org.couchers.api.search.Result.prototype.setCommunity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.org.couchers.api.search.Result.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.clearCommunity = function() {
  return this.setCommunity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.Result.prototype.hasCommunity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional org.couchers.api.groups.Group group = 5;
 * @return {?proto.org.couchers.api.groups.Group}
 */
proto.org.couchers.api.search.Result.prototype.getGroup = function() {
  return /** @type{?proto.org.couchers.api.groups.Group} */ (
    jspb.Message.getWrapperField(this, groups_pb.Group, 5));
};


/**
 * @param {?proto.org.couchers.api.groups.Group|undefined} value
 * @return {!proto.org.couchers.api.search.Result} returns this
*/
proto.org.couchers.api.search.Result.prototype.setGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.org.couchers.api.search.Result.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.Result.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional org.couchers.api.pages.Page place = 6;
 * @return {?proto.org.couchers.api.pages.Page}
 */
proto.org.couchers.api.search.Result.prototype.getPlace = function() {
  return /** @type{?proto.org.couchers.api.pages.Page} */ (
    jspb.Message.getWrapperField(this, pages_pb.Page, 6));
};


/**
 * @param {?proto.org.couchers.api.pages.Page|undefined} value
 * @return {!proto.org.couchers.api.search.Result} returns this
*/
proto.org.couchers.api.search.Result.prototype.setPlace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.org.couchers.api.search.Result.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.clearPlace = function() {
  return this.setPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.Result.prototype.hasPlace = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional org.couchers.api.pages.Page guide = 7;
 * @return {?proto.org.couchers.api.pages.Page}
 */
proto.org.couchers.api.search.Result.prototype.getGuide = function() {
  return /** @type{?proto.org.couchers.api.pages.Page} */ (
    jspb.Message.getWrapperField(this, pages_pb.Page, 7));
};


/**
 * @param {?proto.org.couchers.api.pages.Page|undefined} value
 * @return {!proto.org.couchers.api.search.Result} returns this
*/
proto.org.couchers.api.search.Result.prototype.setGuide = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.org.couchers.api.search.Result.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.clearGuide = function() {
  return this.setGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.Result.prototype.hasGuide = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional org.couchers.api.events.Event event = 8;
 * @return {?proto.org.couchers.api.events.Event}
 */
proto.org.couchers.api.search.Result.prototype.getEvent = function() {
  return /** @type{?proto.org.couchers.api.events.Event} */ (
    jspb.Message.getWrapperField(this, events_pb.Event, 8));
};


/**
 * @param {?proto.org.couchers.api.events.Event|undefined} value
 * @return {!proto.org.couchers.api.search.Result} returns this
*/
proto.org.couchers.api.search.Result.prototype.setEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.org.couchers.api.search.Result.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.Result} returns this
 */
proto.org.couchers.api.search.Result.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.Result.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.search.SearchRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.SearchRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.SearchRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.SearchRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.SearchRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.org.couchers.api.search.Result.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.SearchRes}
 */
proto.org.couchers.api.search.SearchRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.SearchRes;
  return proto.org.couchers.api.search.SearchRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.SearchRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.SearchRes}
 */
proto.org.couchers.api.search.SearchRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.search.Result;
      reader.readMessage(value,proto.org.couchers.api.search.Result.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.SearchRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.SearchRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.SearchRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.SearchRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.search.Result.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Result results = 1;
 * @return {!Array<!proto.org.couchers.api.search.Result>}
 */
proto.org.couchers.api.search.SearchRes.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.search.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.search.Result, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.search.Result>} value
 * @return {!proto.org.couchers.api.search.SearchRes} returns this
*/
proto.org.couchers.api.search.SearchRes.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.search.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.search.Result}
 */
proto.org.couchers.api.search.SearchRes.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.search.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.SearchRes} returns this
 */
proto.org.couchers.api.search.SearchRes.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.search.SearchRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.SearchRes} returns this
 */
proto.org.couchers.api.search.SearchRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.search.UserSearchReq.repeatedFields_ = [5,6,7,8,32];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.search.UserSearchReq.oneofGroups_ = [[28,29,33]];

/**
 * @enum {number}
 */
proto.org.couchers.api.search.UserSearchReq.SearchInCase = {
  SEARCH_IN_NOT_SET: 0,
  SEARCH_IN_AREA: 28,
  SEARCH_IN_COMMUNITY_ID: 29,
  SEARCH_IN_RECTANGLE: 33
};

/**
 * @return {proto.org.couchers.api.search.UserSearchReq.SearchInCase}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getSearchInCase = function() {
  return /** @type {proto.org.couchers.api.search.UserSearchReq.SearchInCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.search.UserSearchReq.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.UserSearchReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.UserSearchReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.UserSearchReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: (f = msg.getQuery()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    queryNameOnly: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    lastActive: (f = msg.getLastActive()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    searchInArea: (f = msg.getSearchInArea()) && proto.org.couchers.api.search.Area.toObject(includeInstance, f),
    searchInCommunityId: jspb.Message.getFieldWithDefault(msg, 29, 0),
    searchInRectangle: (f = msg.getSearchInRectangle()) && proto.org.couchers.api.search.RectArea.toObject(includeInstance, f),
    hostingStatusFilterList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    smokingLocationFilterList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sleepingArrangementFilterList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    parkingDetailsFilterList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    meetupStatusFilterList: (f = jspb.Message.getRepeatedField(msg, 32)) == null ? undefined : f,
    gender: (f = msg.getGender()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    guests: (f = msg.getGuests()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    onlyWithReferences: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    lastMinute: (f = msg.getLastMinute()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hasPets: (f = msg.getHasPets()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    acceptsPets: (f = msg.getAcceptsPets()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hasKids: (f = msg.getHasKids()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    acceptsKids: (f = msg.getAcceptsKids()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hasHousemates: (f = msg.getHasHousemates()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    wheelchairAccessible: (f = msg.getWheelchairAccessible()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    smokesAtHome: (f = msg.getSmokesAtHome()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    drinkingAllowed: (f = msg.getDrinkingAllowed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    drinksAtHome: (f = msg.getDrinksAtHome()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    parking: (f = msg.getParking()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    campingOk: (f = msg.getCampingOk()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    profileCompleted: (f = msg.getProfileCompleted()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.UserSearchReq}
 */
proto.org.couchers.api.search.UserSearchReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.UserSearchReq;
  return proto.org.couchers.api.search.UserSearchReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.UserSearchReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.UserSearchReq}
 */
proto.org.couchers.api.search.UserSearchReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQueryNameOnly(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastActive(value);
      break;
    case 28:
      var value = new proto.org.couchers.api.search.Area;
      reader.readMessage(value,proto.org.couchers.api.search.Area.deserializeBinaryFromReader);
      msg.setSearchInArea(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSearchInCommunityId(value);
      break;
    case 33:
      var value = new proto.org.couchers.api.search.RectArea;
      reader.readMessage(value,proto.org.couchers.api.search.RectArea.deserializeBinaryFromReader);
      msg.setSearchInRectangle(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.org.couchers.api.core.HostingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHostingStatusFilter(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<!proto.org.couchers.api.core.SmokingLocation>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSmokingLocationFilter(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<!proto.org.couchers.api.core.SleepingArrangement>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSleepingArrangementFilter(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<!proto.org.couchers.api.core.ParkingDetails>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addParkingDetailsFilter(values[i]);
      }
      break;
    case 32:
      var values = /** @type {!Array<!proto.org.couchers.api.core.MeetupStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMeetupStatusFilter(values[i]);
      }
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setGender(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setGuests(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyWithReferences(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setLastMinute(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasPets(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAcceptsPets(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasKids(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAcceptsKids(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasHousemates(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setWheelchairAccessible(value);
      break;
    case 23:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSmokesAtHome(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDrinkingAllowed(value);
      break;
    case 25:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDrinksAtHome(value);
      break;
    case 26:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setParking(value);
      break;
    case 27:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCampingOk(value);
      break;
    case 31:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setProfileCompleted(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.UserSearchReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.UserSearchReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.UserSearchReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getQueryNameOnly();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getLastActive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSearchInArea();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.org.couchers.api.search.Area.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeInt64(
      29,
      f
    );
  }
  f = message.getSearchInRectangle();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.org.couchers.api.search.RectArea.serializeBinaryToWriter
    );
  }
  f = message.getHostingStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getSmokingLocationFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getSleepingArrangementFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getParkingDetailsFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getMeetupStatusFilterList();
  if (f.length > 0) {
    writer.writePackedEnum(
      32,
      f
    );
  }
  f = message.getGender();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getGuests();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getOnlyWithReferences();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getLastMinute();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHasPets();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAcceptsPets();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHasKids();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAcceptsKids();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHasHousemates();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getWheelchairAccessible();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSmokesAtHome();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDrinkingAllowed();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDrinksAtHome();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getParking();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCampingOk();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getProfileCompleted();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue query = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getQuery = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool query_name_only = 30;
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getQueryNameOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setQueryNameOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional google.protobuf.Timestamp last_active = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getLastActive = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setLastActive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearLastActive = function() {
  return this.setLastActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasLastActive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Area search_in_area = 28;
 * @return {?proto.org.couchers.api.search.Area}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getSearchInArea = function() {
  return /** @type{?proto.org.couchers.api.search.Area} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.search.Area, 28));
};


/**
 * @param {?proto.org.couchers.api.search.Area|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setSearchInArea = function(value) {
  return jspb.Message.setOneofWrapperField(this, 28, proto.org.couchers.api.search.UserSearchReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearSearchInArea = function() {
  return this.setSearchInArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasSearchInArea = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional int64 search_in_community_id = 29;
 * @return {number}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getSearchInCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setSearchInCommunityId = function(value) {
  return jspb.Message.setOneofField(this, 29, proto.org.couchers.api.search.UserSearchReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearSearchInCommunityId = function() {
  return jspb.Message.setOneofField(this, 29, proto.org.couchers.api.search.UserSearchReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasSearchInCommunityId = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional RectArea search_in_rectangle = 33;
 * @return {?proto.org.couchers.api.search.RectArea}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getSearchInRectangle = function() {
  return /** @type{?proto.org.couchers.api.search.RectArea} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.search.RectArea, 33));
};


/**
 * @param {?proto.org.couchers.api.search.RectArea|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setSearchInRectangle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 33, proto.org.couchers.api.search.UserSearchReq.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearSearchInRectangle = function() {
  return this.setSearchInRectangle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasSearchInRectangle = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * repeated org.couchers.api.core.HostingStatus hosting_status_filter = 5;
 * @return {!Array<!proto.org.couchers.api.core.HostingStatus>}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getHostingStatusFilterList = function() {
  return /** @type {!Array<!proto.org.couchers.api.core.HostingStatus>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.HostingStatus>} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setHostingStatusFilterList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.org.couchers.api.core.HostingStatus} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.addHostingStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearHostingStatusFilterList = function() {
  return this.setHostingStatusFilterList([]);
};


/**
 * repeated org.couchers.api.core.SmokingLocation smoking_location_filter = 6;
 * @return {!Array<!proto.org.couchers.api.core.SmokingLocation>}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getSmokingLocationFilterList = function() {
  return /** @type {!Array<!proto.org.couchers.api.core.SmokingLocation>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.SmokingLocation>} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setSmokingLocationFilterList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.org.couchers.api.core.SmokingLocation} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.addSmokingLocationFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearSmokingLocationFilterList = function() {
  return this.setSmokingLocationFilterList([]);
};


/**
 * repeated org.couchers.api.core.SleepingArrangement sleeping_arrangement_filter = 7;
 * @return {!Array<!proto.org.couchers.api.core.SleepingArrangement>}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getSleepingArrangementFilterList = function() {
  return /** @type {!Array<!proto.org.couchers.api.core.SleepingArrangement>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.SleepingArrangement>} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setSleepingArrangementFilterList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.org.couchers.api.core.SleepingArrangement} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.addSleepingArrangementFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearSleepingArrangementFilterList = function() {
  return this.setSleepingArrangementFilterList([]);
};


/**
 * repeated org.couchers.api.core.ParkingDetails parking_details_filter = 8;
 * @return {!Array<!proto.org.couchers.api.core.ParkingDetails>}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getParkingDetailsFilterList = function() {
  return /** @type {!Array<!proto.org.couchers.api.core.ParkingDetails>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.ParkingDetails>} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setParkingDetailsFilterList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.org.couchers.api.core.ParkingDetails} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.addParkingDetailsFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearParkingDetailsFilterList = function() {
  return this.setParkingDetailsFilterList([]);
};


/**
 * repeated org.couchers.api.core.MeetupStatus meetup_status_filter = 32;
 * @return {!Array<!proto.org.couchers.api.core.MeetupStatus>}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getMeetupStatusFilterList = function() {
  return /** @type {!Array<!proto.org.couchers.api.core.MeetupStatus>} */ (jspb.Message.getRepeatedField(this, 32));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.MeetupStatus>} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setMeetupStatusFilterList = function(value) {
  return jspb.Message.setField(this, 32, value || []);
};


/**
 * @param {!proto.org.couchers.api.core.MeetupStatus} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.addMeetupStatusFilter = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 32, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearMeetupStatusFilterList = function() {
  return this.setMeetupStatusFilterList([]);
};


/**
 * optional google.protobuf.StringValue gender = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getGender = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setGender = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearGender = function() {
  return this.setGender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasGender = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.UInt32Value guests = 10;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getGuests = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 10));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setGuests = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearGuests = function() {
  return this.setGuests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasGuests = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool only_with_references = 12;
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getOnlyWithReferences = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setOnlyWithReferences = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional google.protobuf.BoolValue last_minute = 16;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getLastMinute = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 16));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setLastMinute = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearLastMinute = function() {
  return this.setLastMinute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasLastMinute = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.BoolValue has_pets = 17;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getHasPets = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 17));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setHasPets = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearHasPets = function() {
  return this.setHasPets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasHasPets = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.BoolValue accepts_pets = 18;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getAcceptsPets = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 18));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setAcceptsPets = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearAcceptsPets = function() {
  return this.setAcceptsPets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasAcceptsPets = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.BoolValue has_kids = 19;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getHasKids = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 19));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setHasKids = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearHasKids = function() {
  return this.setHasKids(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasHasKids = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.BoolValue accepts_kids = 20;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getAcceptsKids = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 20));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setAcceptsKids = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearAcceptsKids = function() {
  return this.setAcceptsKids(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasAcceptsKids = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.BoolValue has_housemates = 21;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getHasHousemates = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 21));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setHasHousemates = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearHasHousemates = function() {
  return this.setHasHousemates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasHasHousemates = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.BoolValue wheelchair_accessible = 22;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getWheelchairAccessible = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 22));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setWheelchairAccessible = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearWheelchairAccessible = function() {
  return this.setWheelchairAccessible(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasWheelchairAccessible = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.BoolValue smokes_at_home = 23;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getSmokesAtHome = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 23));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setSmokesAtHome = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearSmokesAtHome = function() {
  return this.setSmokesAtHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasSmokesAtHome = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.BoolValue drinking_allowed = 24;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getDrinkingAllowed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 24));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setDrinkingAllowed = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearDrinkingAllowed = function() {
  return this.setDrinkingAllowed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasDrinkingAllowed = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.BoolValue drinks_at_home = 25;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getDrinksAtHome = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 25));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setDrinksAtHome = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearDrinksAtHome = function() {
  return this.setDrinksAtHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasDrinksAtHome = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.BoolValue parking = 26;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getParking = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 26));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setParking = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearParking = function() {
  return this.setParking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasParking = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.BoolValue camping_ok = 27;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getCampingOk = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 27));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setCampingOk = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearCampingOk = function() {
  return this.setCampingOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasCampingOk = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.BoolValue profile_completed = 31;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getProfileCompleted = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 31));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
*/
proto.org.couchers.api.search.UserSearchReq.prototype.setProfileCompleted = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.clearProfileCompleted = function() {
  return this.setProfileCompleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.hasProfileCompleted = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.search.UserSearchReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.UserSearchReq} returns this
 */
proto.org.couchers.api.search.UserSearchReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.search.UserSearchRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.UserSearchRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.UserSearchRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.UserSearchRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.UserSearchRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.org.couchers.api.search.Result.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.UserSearchRes}
 */
proto.org.couchers.api.search.UserSearchRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.UserSearchRes;
  return proto.org.couchers.api.search.UserSearchRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.UserSearchRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.UserSearchRes}
 */
proto.org.couchers.api.search.UserSearchRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.search.Result;
      reader.readMessage(value,proto.org.couchers.api.search.Result.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.UserSearchRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.UserSearchRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.UserSearchRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.UserSearchRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.search.Result.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Result results = 1;
 * @return {!Array<!proto.org.couchers.api.search.Result>}
 */
proto.org.couchers.api.search.UserSearchRes.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.search.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.search.Result, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.search.Result>} value
 * @return {!proto.org.couchers.api.search.UserSearchRes} returns this
*/
proto.org.couchers.api.search.UserSearchRes.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.search.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.search.Result}
 */
proto.org.couchers.api.search.UserSearchRes.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.search.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.UserSearchRes} returns this
 */
proto.org.couchers.api.search.UserSearchRes.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.search.UserSearchRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.UserSearchRes} returns this
 */
proto.org.couchers.api.search.UserSearchRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.search.EventSearchReq.oneofGroups_ = [[12,13],[5,6,7]];

/**
 * @enum {number}
 */
proto.org.couchers.api.search.EventSearchReq.OnlineStatusCase = {
  ONLINE_STATUS_NOT_SET: 0,
  ONLY_ONLINE: 12,
  ONLY_OFFLINE: 13
};

/**
 * @return {proto.org.couchers.api.search.EventSearchReq.OnlineStatusCase}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getOnlineStatusCase = function() {
  return /** @type {proto.org.couchers.api.search.EventSearchReq.OnlineStatusCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[0]));
};

/**
 * @enum {number}
 */
proto.org.couchers.api.search.EventSearchReq.SearchInCase = {
  SEARCH_IN_NOT_SET: 0,
  SEARCH_IN_AREA: 5,
  SEARCH_IN_COMMUNITY_ID: 6,
  SEARCH_IN_RECTANGLE: 7
};

/**
 * @return {proto.org.couchers.api.search.EventSearchReq.SearchInCase}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getSearchInCase = function() {
  return /** @type {proto.org.couchers.api.search.EventSearchReq.SearchInCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[1]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.EventSearchReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.EventSearchReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.EventSearchReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    past: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    query: (f = msg.getQuery()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    queryTitleOnly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    onlyOnline: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    onlyOffline: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    subscribed: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    attending: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    organizing: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    myCommunities: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    includeCancelled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    searchInArea: (f = msg.getSearchInArea()) && proto.org.couchers.api.search.Area.toObject(includeInstance, f),
    searchInCommunityId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    searchInRectangle: (f = msg.getSearchInRectangle()) && proto.org.couchers.api.search.RectArea.toObject(includeInstance, f),
    after: (f = msg.getAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    before: (f = msg.getBefore()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pageSize: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.EventSearchReq}
 */
proto.org.couchers.api.search.EventSearchReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.EventSearchReq;
  return proto.org.couchers.api.search.EventSearchReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.EventSearchReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.EventSearchReq}
 */
proto.org.couchers.api.search.EventSearchReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPast(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setQuery(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQueryTitleOnly(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyOnline(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyOffline(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSubscribed(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAttending(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrganizing(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMyCommunities(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCancelled(value);
      break;
    case 5:
      var value = new proto.org.couchers.api.search.Area;
      reader.readMessage(value,proto.org.couchers.api.search.Area.deserializeBinaryFromReader);
      msg.setSearchInArea(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSearchInCommunityId(value);
      break;
    case 7:
      var value = new proto.org.couchers.api.search.RectArea;
      reader.readMessage(value,proto.org.couchers.api.search.RectArea.deserializeBinaryFromReader);
      msg.setSearchInRectangle(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAfter(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBefore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.EventSearchReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.EventSearchReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.EventSearchReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPast();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getQuery();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getQueryTitleOnly();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSubscribed();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getAttending();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getOrganizing();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getMyCommunities();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getIncludeCancelled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSearchInArea();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.org.couchers.api.search.Area.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSearchInRectangle();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.org.couchers.api.search.RectArea.serializeBinaryToWriter
    );
  }
  f = message.getAfter();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBefore();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional bool past = 1;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getPast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setPast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue query = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getQuery = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
*/
proto.org.couchers.api.search.EventSearchReq.prototype.setQuery = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearQuery = function() {
  return this.setQuery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasQuery = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool query_title_only = 3;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getQueryTitleOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setQueryTitleOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool only_online = 12;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getOnlyOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setOnlyOnline = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearOnlyOnline = function() {
  return jspb.Message.setOneofField(this, 12, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasOnlyOnline = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool only_offline = 13;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getOnlyOffline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setOnlyOffline = function(value) {
  return jspb.Message.setOneofField(this, 13, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearOnlyOffline = function() {
  return jspb.Message.setOneofField(this, 13, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasOnlyOffline = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool subscribed = 14;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getSubscribed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setSubscribed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool attending = 15;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getAttending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setAttending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool organizing = 16;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getOrganizing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setOrganizing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool my_communities = 17;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getMyCommunities = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setMyCommunities = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool include_cancelled = 4;
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getIncludeCancelled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setIncludeCancelled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional Area search_in_area = 5;
 * @return {?proto.org.couchers.api.search.Area}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getSearchInArea = function() {
  return /** @type{?proto.org.couchers.api.search.Area} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.search.Area, 5));
};


/**
 * @param {?proto.org.couchers.api.search.Area|undefined} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
*/
proto.org.couchers.api.search.EventSearchReq.prototype.setSearchInArea = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearSearchInArea = function() {
  return this.setSearchInArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasSearchInArea = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 search_in_community_id = 6;
 * @return {number}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getSearchInCommunityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setSearchInCommunityId = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[1], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearSearchInCommunityId = function() {
  return jspb.Message.setOneofField(this, 6, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[1], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasSearchInCommunityId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RectArea search_in_rectangle = 7;
 * @return {?proto.org.couchers.api.search.RectArea}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getSearchInRectangle = function() {
  return /** @type{?proto.org.couchers.api.search.RectArea} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.search.RectArea, 7));
};


/**
 * @param {?proto.org.couchers.api.search.RectArea|undefined} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
*/
proto.org.couchers.api.search.EventSearchReq.prototype.setSearchInRectangle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.org.couchers.api.search.EventSearchReq.oneofGroups_[1], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearSearchInRectangle = function() {
  return this.setSearchInRectangle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasSearchInRectangle = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp after = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getAfter = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
*/
proto.org.couchers.api.search.EventSearchReq.prototype.setAfter = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearAfter = function() {
  return this.setAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasAfter = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp before = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getBefore = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
*/
proto.org.couchers.api.search.EventSearchReq.prototype.setBefore = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.clearBefore = function() {
  return this.setBefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.hasBefore = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint32 page_size = 10;
 * @return {number}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string page_token = 11;
 * @return {string}
 */
proto.org.couchers.api.search.EventSearchReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.EventSearchReq} returns this
 */
proto.org.couchers.api.search.EventSearchReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.search.EventSearchRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.search.EventSearchRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.search.EventSearchRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.search.EventSearchRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.EventSearchRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    events_pb.Event.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.search.EventSearchRes}
 */
proto.org.couchers.api.search.EventSearchRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.search.EventSearchRes;
  return proto.org.couchers.api.search.EventSearchRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.search.EventSearchRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.search.EventSearchRes}
 */
proto.org.couchers.api.search.EventSearchRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new events_pb.Event;
      reader.readMessage(value,events_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.search.EventSearchRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.search.EventSearchRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.search.EventSearchRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.search.EventSearchRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      events_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated org.couchers.api.events.Event events = 1;
 * @return {!Array<!proto.org.couchers.api.events.Event>}
 */
proto.org.couchers.api.search.EventSearchRes.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.events.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, events_pb.Event, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.events.Event>} value
 * @return {!proto.org.couchers.api.search.EventSearchRes} returns this
*/
proto.org.couchers.api.search.EventSearchRes.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.events.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.events.Event}
 */
proto.org.couchers.api.search.EventSearchRes.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.events.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.search.EventSearchRes} returns this
 */
proto.org.couchers.api.search.EventSearchRes.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.search.EventSearchRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.search.EventSearchRes} returns this
 */
proto.org.couchers.api.search.EventSearchRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.org.couchers.api.search);
