// source: notifications.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
goog.exportSymbol('proto.org.couchers.notifications.GetNotificationSettingsReq', null, global);
goog.exportSymbol('proto.org.couchers.notifications.GetNotificationSettingsRes', null, global);
goog.exportSymbol('proto.org.couchers.notifications.GetVapidPublicKeyRes', null, global);
goog.exportSymbol('proto.org.couchers.notifications.ListNotificationsReq', null, global);
goog.exportSymbol('proto.org.couchers.notifications.ListNotificationsRes', null, global);
goog.exportSymbol('proto.org.couchers.notifications.Notification', null, global);
goog.exportSymbol('proto.org.couchers.notifications.NotificationGroup', null, global);
goog.exportSymbol('proto.org.couchers.notifications.NotificationItem', null, global);
goog.exportSymbol('proto.org.couchers.notifications.NotificationTopic', null, global);
goog.exportSymbol('proto.org.couchers.notifications.RegisterPushNotificationReq', null, global);
goog.exportSymbol('proto.org.couchers.notifications.SetNotificationSettingsReq', null, global);
goog.exportSymbol('proto.org.couchers.notifications.SingleNotificationPreference', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.GetNotificationSettingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.notifications.GetNotificationSettingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.GetNotificationSettingsReq.displayName = 'proto.org.couchers.notifications.GetNotificationSettingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.NotificationItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.notifications.NotificationItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.NotificationItem.displayName = 'proto.org.couchers.notifications.NotificationItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.NotificationTopic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.notifications.NotificationTopic.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.notifications.NotificationTopic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.NotificationTopic.displayName = 'proto.org.couchers.notifications.NotificationTopic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.NotificationGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.notifications.NotificationGroup.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.notifications.NotificationGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.NotificationGroup.displayName = 'proto.org.couchers.notifications.NotificationGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.SingleNotificationPreference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.notifications.SingleNotificationPreference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.SingleNotificationPreference.displayName = 'proto.org.couchers.notifications.SingleNotificationPreference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.GetNotificationSettingsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.notifications.GetNotificationSettingsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.notifications.GetNotificationSettingsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.GetNotificationSettingsRes.displayName = 'proto.org.couchers.notifications.GetNotificationSettingsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.SetNotificationSettingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.notifications.SetNotificationSettingsReq.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.notifications.SetNotificationSettingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.SetNotificationSettingsReq.displayName = 'proto.org.couchers.notifications.SetNotificationSettingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.notifications.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.Notification.displayName = 'proto.org.couchers.notifications.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.ListNotificationsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.notifications.ListNotificationsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.ListNotificationsReq.displayName = 'proto.org.couchers.notifications.ListNotificationsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.ListNotificationsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.notifications.ListNotificationsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.notifications.ListNotificationsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.ListNotificationsRes.displayName = 'proto.org.couchers.notifications.ListNotificationsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.notifications.GetVapidPublicKeyRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.GetVapidPublicKeyRes.displayName = 'proto.org.couchers.notifications.GetVapidPublicKeyRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.notifications.RegisterPushNotificationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.notifications.RegisterPushNotificationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.notifications.RegisterPushNotificationReq.displayName = 'proto.org.couchers.notifications.RegisterPushNotificationReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.GetNotificationSettingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.GetNotificationSettingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.GetNotificationSettingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsReq}
 */
proto.org.couchers.notifications.GetNotificationSettingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.GetNotificationSettingsReq;
  return proto.org.couchers.notifications.GetNotificationSettingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsReq}
 */
proto.org.couchers.notifications.GetNotificationSettingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.GetNotificationSettingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.GetNotificationSettingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.GetNotificationSettingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.NotificationItem.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.NotificationItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.NotificationItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.NotificationItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userEditable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    push: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    email: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    digest: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.NotificationItem}
 */
proto.org.couchers.notifications.NotificationItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.NotificationItem;
  return proto.org.couchers.notifications.NotificationItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.NotificationItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.NotificationItem}
 */
proto.org.couchers.notifications.NotificationItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserEditable(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPush(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDigest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.NotificationItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.NotificationItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.NotificationItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.NotificationItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserEditable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPush();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDigest();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string action = 1;
 * @return {string}
 */
proto.org.couchers.notifications.NotificationItem.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.NotificationItem} returns this
 */
proto.org.couchers.notifications.NotificationItem.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.org.couchers.notifications.NotificationItem.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.NotificationItem} returns this
 */
proto.org.couchers.notifications.NotificationItem.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool user_editable = 3;
 * @return {boolean}
 */
proto.org.couchers.notifications.NotificationItem.prototype.getUserEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.NotificationItem} returns this
 */
proto.org.couchers.notifications.NotificationItem.prototype.setUserEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool push = 4;
 * @return {boolean}
 */
proto.org.couchers.notifications.NotificationItem.prototype.getPush = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.NotificationItem} returns this
 */
proto.org.couchers.notifications.NotificationItem.prototype.setPush = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool email = 5;
 * @return {boolean}
 */
proto.org.couchers.notifications.NotificationItem.prototype.getEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.NotificationItem} returns this
 */
proto.org.couchers.notifications.NotificationItem.prototype.setEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool digest = 6;
 * @return {boolean}
 */
proto.org.couchers.notifications.NotificationItem.prototype.getDigest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.NotificationItem} returns this
 */
proto.org.couchers.notifications.NotificationItem.prototype.setDigest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.notifications.NotificationTopic.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.NotificationTopic.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.NotificationTopic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.NotificationTopic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.NotificationTopic.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.org.couchers.notifications.NotificationItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.NotificationTopic}
 */
proto.org.couchers.notifications.NotificationTopic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.NotificationTopic;
  return proto.org.couchers.notifications.NotificationTopic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.NotificationTopic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.NotificationTopic}
 */
proto.org.couchers.notifications.NotificationTopic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.org.couchers.notifications.NotificationItem;
      reader.readMessage(value,proto.org.couchers.notifications.NotificationItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.NotificationTopic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.NotificationTopic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.NotificationTopic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.NotificationTopic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.org.couchers.notifications.NotificationItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.org.couchers.notifications.NotificationTopic.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.NotificationTopic} returns this
 */
proto.org.couchers.notifications.NotificationTopic.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.org.couchers.notifications.NotificationTopic.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.NotificationTopic} returns this
 */
proto.org.couchers.notifications.NotificationTopic.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated NotificationItem items = 3;
 * @return {!Array<!proto.org.couchers.notifications.NotificationItem>}
 */
proto.org.couchers.notifications.NotificationTopic.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.org.couchers.notifications.NotificationItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.notifications.NotificationItem, 3));
};


/**
 * @param {!Array<!proto.org.couchers.notifications.NotificationItem>} value
 * @return {!proto.org.couchers.notifications.NotificationTopic} returns this
*/
proto.org.couchers.notifications.NotificationTopic.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.org.couchers.notifications.NotificationItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.notifications.NotificationItem}
 */
proto.org.couchers.notifications.NotificationTopic.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.org.couchers.notifications.NotificationItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.notifications.NotificationTopic} returns this
 */
proto.org.couchers.notifications.NotificationTopic.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.notifications.NotificationGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.NotificationGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.NotificationGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.NotificationGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.NotificationGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    heading: jspb.Message.getFieldWithDefault(msg, 1, ""),
    topicsList: jspb.Message.toObjectList(msg.getTopicsList(),
    proto.org.couchers.notifications.NotificationTopic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.NotificationGroup}
 */
proto.org.couchers.notifications.NotificationGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.NotificationGroup;
  return proto.org.couchers.notifications.NotificationGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.NotificationGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.NotificationGroup}
 */
proto.org.couchers.notifications.NotificationGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeading(value);
      break;
    case 2:
      var value = new proto.org.couchers.notifications.NotificationTopic;
      reader.readMessage(value,proto.org.couchers.notifications.NotificationTopic.deserializeBinaryFromReader);
      msg.addTopics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.NotificationGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.NotificationGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.NotificationGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.NotificationGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeading();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTopicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.org.couchers.notifications.NotificationTopic.serializeBinaryToWriter
    );
  }
};


/**
 * optional string heading = 1;
 * @return {string}
 */
proto.org.couchers.notifications.NotificationGroup.prototype.getHeading = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.NotificationGroup} returns this
 */
proto.org.couchers.notifications.NotificationGroup.prototype.setHeading = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated NotificationTopic topics = 2;
 * @return {!Array<!proto.org.couchers.notifications.NotificationTopic>}
 */
proto.org.couchers.notifications.NotificationGroup.prototype.getTopicsList = function() {
  return /** @type{!Array<!proto.org.couchers.notifications.NotificationTopic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.notifications.NotificationTopic, 2));
};


/**
 * @param {!Array<!proto.org.couchers.notifications.NotificationTopic>} value
 * @return {!proto.org.couchers.notifications.NotificationGroup} returns this
*/
proto.org.couchers.notifications.NotificationGroup.prototype.setTopicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.org.couchers.notifications.NotificationTopic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.notifications.NotificationTopic}
 */
proto.org.couchers.notifications.NotificationGroup.prototype.addTopics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.org.couchers.notifications.NotificationTopic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.notifications.NotificationGroup} returns this
 */
proto.org.couchers.notifications.NotificationGroup.prototype.clearTopicsList = function() {
  return this.setTopicsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.SingleNotificationPreference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.SingleNotificationPreference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.SingleNotificationPreference.toObject = function(includeInstance, msg) {
  var f, obj = {
    topic: jspb.Message.getFieldWithDefault(msg, 1, ""),
    action: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deliveryMethod: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.SingleNotificationPreference}
 */
proto.org.couchers.notifications.SingleNotificationPreference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.SingleNotificationPreference;
  return proto.org.couchers.notifications.SingleNotificationPreference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.SingleNotificationPreference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.SingleNotificationPreference}
 */
proto.org.couchers.notifications.SingleNotificationPreference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveryMethod(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.SingleNotificationPreference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.SingleNotificationPreference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.SingleNotificationPreference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeliveryMethod();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string topic = 1;
 * @return {string}
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.SingleNotificationPreference} returns this
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string action = 2;
 * @return {string}
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.SingleNotificationPreference} returns this
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string delivery_method = 3;
 * @return {string}
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.getDeliveryMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.SingleNotificationPreference} returns this
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.setDeliveryMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool enabled = 4;
 * @return {boolean}
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.SingleNotificationPreference} returns this
 */
proto.org.couchers.notifications.SingleNotificationPreference.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.GetNotificationSettingsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    doNotEmailEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    emailDigestEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.org.couchers.notifications.NotificationGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsRes}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.GetNotificationSettingsRes;
  return proto.org.couchers.notifications.GetNotificationSettingsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsRes}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDoNotEmailEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailDigestEnabled(value);
      break;
    case 3:
      var value = new proto.org.couchers.notifications.NotificationGroup;
      reader.readMessage(value,proto.org.couchers.notifications.NotificationGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.GetNotificationSettingsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.GetNotificationSettingsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoNotEmailEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getEmailDigestEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.org.couchers.notifications.NotificationGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool do_not_email_enabled = 1;
 * @return {boolean}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.getDoNotEmailEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsRes} returns this
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.setDoNotEmailEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool email_digest_enabled = 2;
 * @return {boolean}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.getEmailDigestEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsRes} returns this
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.setEmailDigestEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated NotificationGroup groups = 3;
 * @return {!Array<!proto.org.couchers.notifications.NotificationGroup>}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.org.couchers.notifications.NotificationGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.notifications.NotificationGroup, 3));
};


/**
 * @param {!Array<!proto.org.couchers.notifications.NotificationGroup>} value
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsRes} returns this
*/
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.org.couchers.notifications.NotificationGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.notifications.NotificationGroup}
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.org.couchers.notifications.NotificationGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.notifications.GetNotificationSettingsRes} returns this
 */
proto.org.couchers.notifications.GetNotificationSettingsRes.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.SetNotificationSettingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.SetNotificationSettingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    enableDoNotEmail: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    preferencesList: jspb.Message.toObjectList(msg.getPreferencesList(),
    proto.org.couchers.notifications.SingleNotificationPreference.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.SetNotificationSettingsReq}
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.SetNotificationSettingsReq;
  return proto.org.couchers.notifications.SetNotificationSettingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.SetNotificationSettingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.SetNotificationSettingsReq}
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableDoNotEmail(value);
      break;
    case 2:
      var value = new proto.org.couchers.notifications.SingleNotificationPreference;
      reader.readMessage(value,proto.org.couchers.notifications.SingleNotificationPreference.deserializeBinaryFromReader);
      msg.addPreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.SetNotificationSettingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.SetNotificationSettingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnableDoNotEmail();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPreferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.org.couchers.notifications.SingleNotificationPreference.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool enable_do_not_email = 1;
 * @return {boolean}
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.getEnableDoNotEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.notifications.SetNotificationSettingsReq} returns this
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.setEnableDoNotEmail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated SingleNotificationPreference preferences = 2;
 * @return {!Array<!proto.org.couchers.notifications.SingleNotificationPreference>}
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.getPreferencesList = function() {
  return /** @type{!Array<!proto.org.couchers.notifications.SingleNotificationPreference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.notifications.SingleNotificationPreference, 2));
};


/**
 * @param {!Array<!proto.org.couchers.notifications.SingleNotificationPreference>} value
 * @return {!proto.org.couchers.notifications.SetNotificationSettingsReq} returns this
*/
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.setPreferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.org.couchers.notifications.SingleNotificationPreference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.notifications.SingleNotificationPreference}
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.addPreferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.org.couchers.notifications.SingleNotificationPreference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.notifications.SetNotificationSettingsReq} returns this
 */
proto.org.couchers.notifications.SetNotificationSettingsReq.prototype.clearPreferencesList = function() {
  return this.setPreferencesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    topic: jspb.Message.getFieldWithDefault(msg, 3, ""),
    action: jspb.Message.getFieldWithDefault(msg, 4, ""),
    key: jspb.Message.getFieldWithDefault(msg, 5, ""),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    body: jspb.Message.getFieldWithDefault(msg, 7, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 8, ""),
    url: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.Notification}
 */
proto.org.couchers.notifications.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.Notification;
  return proto.org.couchers.notifications.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.Notification}
 */
proto.org.couchers.notifications.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNotificationId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTopic();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 notification_id = 1;
 * @return {number}
 */
proto.org.couchers.notifications.Notification.prototype.getNotificationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setNotificationId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.notifications.Notification.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
*/
proto.org.couchers.notifications.Notification.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.notifications.Notification.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string topic = 3;
 * @return {string}
 */
proto.org.couchers.notifications.Notification.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setTopic = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action = 4;
 * @return {string}
 */
proto.org.couchers.notifications.Notification.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string key = 5;
 * @return {string}
 */
proto.org.couchers.notifications.Notification.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.org.couchers.notifications.Notification.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string body = 7;
 * @return {string}
 */
proto.org.couchers.notifications.Notification.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string icon = 8;
 * @return {string}
 */
proto.org.couchers.notifications.Notification.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string url = 9;
 * @return {string}
 */
proto.org.couchers.notifications.Notification.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.Notification} returns this
 */
proto.org.couchers.notifications.Notification.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.ListNotificationsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.ListNotificationsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.ListNotificationsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.ListNotificationsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.ListNotificationsReq}
 */
proto.org.couchers.notifications.ListNotificationsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.ListNotificationsReq;
  return proto.org.couchers.notifications.ListNotificationsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.ListNotificationsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.ListNotificationsReq}
 */
proto.org.couchers.notifications.ListNotificationsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.ListNotificationsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.ListNotificationsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.ListNotificationsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.ListNotificationsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 page_size = 1;
 * @return {number}
 */
proto.org.couchers.notifications.ListNotificationsReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.notifications.ListNotificationsReq} returns this
 */
proto.org.couchers.notifications.ListNotificationsReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string page_token = 2;
 * @return {string}
 */
proto.org.couchers.notifications.ListNotificationsReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.ListNotificationsReq} returns this
 */
proto.org.couchers.notifications.ListNotificationsReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.notifications.ListNotificationsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.ListNotificationsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.ListNotificationsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.ListNotificationsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.ListNotificationsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.org.couchers.notifications.Notification.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.ListNotificationsRes}
 */
proto.org.couchers.notifications.ListNotificationsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.ListNotificationsRes;
  return proto.org.couchers.notifications.ListNotificationsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.ListNotificationsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.ListNotificationsRes}
 */
proto.org.couchers.notifications.ListNotificationsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.notifications.Notification;
      reader.readMessage(value,proto.org.couchers.notifications.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.ListNotificationsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.ListNotificationsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.ListNotificationsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.ListNotificationsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.notifications.Notification.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Notification notifications = 1;
 * @return {!Array<!proto.org.couchers.notifications.Notification>}
 */
proto.org.couchers.notifications.ListNotificationsRes.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.org.couchers.notifications.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.notifications.Notification, 1));
};


/**
 * @param {!Array<!proto.org.couchers.notifications.Notification>} value
 * @return {!proto.org.couchers.notifications.ListNotificationsRes} returns this
*/
proto.org.couchers.notifications.ListNotificationsRes.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.notifications.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.notifications.Notification}
 */
proto.org.couchers.notifications.ListNotificationsRes.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.notifications.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.notifications.ListNotificationsRes} returns this
 */
proto.org.couchers.notifications.ListNotificationsRes.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.notifications.ListNotificationsRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.ListNotificationsRes} returns this
 */
proto.org.couchers.notifications.ListNotificationsRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.GetVapidPublicKeyRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.GetVapidPublicKeyRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    vapidPublicKey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.GetVapidPublicKeyRes}
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.GetVapidPublicKeyRes;
  return proto.org.couchers.notifications.GetVapidPublicKeyRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.GetVapidPublicKeyRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.GetVapidPublicKeyRes}
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVapidPublicKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.GetVapidPublicKeyRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.GetVapidPublicKeyRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVapidPublicKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vapid_public_key = 1;
 * @return {string}
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.prototype.getVapidPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.GetVapidPublicKeyRes} returns this
 */
proto.org.couchers.notifications.GetVapidPublicKeyRes.prototype.setVapidPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.notifications.RegisterPushNotificationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.notifications.RegisterPushNotificationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    endpoint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    authKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    p256dhKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fullSubscriptionJson: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userAgent: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.notifications.RegisterPushNotificationReq}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.notifications.RegisterPushNotificationReq;
  return proto.org.couchers.notifications.RegisterPushNotificationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.notifications.RegisterPushNotificationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.notifications.RegisterPushNotificationReq}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setP256dhKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullSubscriptionJson(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAgent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.notifications.RegisterPushNotificationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.notifications.RegisterPushNotificationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuthKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getP256dhKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFullSubscriptionJson();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserAgent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string endpoint = 1;
 * @return {string}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.RegisterPushNotificationReq} returns this
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string auth_key = 2;
 * @return {string}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.getAuthKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.RegisterPushNotificationReq} returns this
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.setAuthKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string p256dh_key = 3;
 * @return {string}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.getP256dhKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.RegisterPushNotificationReq} returns this
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.setP256dhKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string full_subscription_json = 4;
 * @return {string}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.getFullSubscriptionJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.RegisterPushNotificationReq} returns this
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.setFullSubscriptionJson = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string user_agent = 5;
 * @return {string}
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.getUserAgent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.notifications.RegisterPushNotificationReq} returns this
 */
proto.org.couchers.notifications.RegisterPushNotificationReq.prototype.setUserAgent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.org.couchers.notifications);
